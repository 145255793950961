
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiCommunityUserAdd, apiCommunityUserEdit, apiCommunityUserDetail } from '@/api/community/community_user'

import { debounce } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'

@Component({
  components: {

  }
})
export default class communityUserDetail extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID



  // 添加商城表单数据
  formData: any = {
     id: '',
    user_id: '',
    signature: '',
    image: '',
  };

  // 表单校验
  rules = {
        user_id: [{
        required: true,
        message: '请输入用户id',
        trigger: ['blur']
    }]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handlecommunityUserAdd()
      } else {
        this.handlecommunityUserEdit()
      }
    })
  }

  // 添加商城
  async handlecommunityUserAdd(): Promise<void> {
    await apiCommunityUserAdd({ ...this.formData })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handlecommunityUserEdit(): Promise<void> {
    await apiCommunityUserEdit({ ...this.formData, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getcommunityUserDetail(): Promise<void> {
    const res: any = await apiCommunityUserDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.formData, key, res[key])
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getcommunityUserDetail()
    }

    this.onSubmit = debounce(this.onSubmit, 2000)
  }
  /** E Life Cycle **/
}
