import request from '@/plugins/axios'


// 用户信息列表
export const apiCommunityUserLists = (params: any) => request.get('/community.community_user/lists', { params })

// 添加用户信息
export const apiCommunityUserAdd = (params: any) => request.post('/community.community_user/add', params)

// 编辑用户信息
export const apiCommunityUserEdit = (params: any) => request.post('/community.community_user/edit', params)

// 删除用户信息
export const apiCommunityUserDelete = (params: any) => request.post('/community.community_user/delete', params)

// 用户信息详情
export const apiCommunityUserDetail = (params: any) => request.get('/community.community_user/detail', { params })

export const apiCommunityUserSwitchStatus = (params: any) => request.post('/community.community_user/status', params)